import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
	{
		path: '/',
		name: '首页',
		component: () => import('../pages/home.vue')
	},
	{
		path: '/erp',
		name: 'erp',
		component: () => import('../pages/erp.vue')
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('../pages/product.vue')
	},
	{
		path: '/production',
		name: 'production',
		component: () => import('../pages/production.vue')
	},
	
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
