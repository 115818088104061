<template>
	<div id="app" ref="app">
		<header :class="headerClassName">
			<div class="header-warp">
				<h1><img src="@/assets/images/logo19060.png" alt="" /></h1>
				<ul class="menu">
					<li
						@click="
							type = 1
							upTop2()
							$router.push('/')
						"
						:class="type == 1 ? 'active' : ''"
					>
						首页
					</li>
					<li
						@click="
							type = 2
							upTop2()
							$router.push('/erp')
						"
						:class="type == 2 ? 'active' : ''"
					>
						外贸ERP系统
					</li>
					<li
						@click="
							type = 3
							upTop2()
							$router.push('/product')
						"
						:class="type == 3 ? 'active' : ''"
					>
						云仓供应链
					</li>
					<li @click="
							type = 4
							upTop2()
							$router.push('/production')
						"
						:class="type == 4 ? 'active' : ''">数字生产系统</li>
					<li @click="toAnli">客户案例</li>
					<!-- <li>关于我们</li> -->
				</ul>
				<div class="user-btn">
					<button class="btn-1" @click="show = true">免费试用</button>
					<!-- <button class="btn-2">注册</button> -->
					<button class="btn-3" @click="toLogin">登录</button>
				</div>
				<div class="open-menu-btn dn">
					<i
						class="iconfont"
						style="color: #fff"
						@click="menuType = true"
						>&#xe6c0;</i
					>
				</div>
			</div>
		</header>
		<div class="tool-warp">
			<div class="phone-box" @click="show = true">
				<img src="@/assets/images/icon_ljsy.png" alt="" />
				<div class="phone-dtl-box">
					<div
						style="
							color: #333;
							line-height: 50px;
							text-align: center;
							font-size: 16px;
						"
						class="label"
						@click="show = true"
					>
						申请试用
					</div>
				</div>
			</div>
			<div class="phone-box">
				<img src="@/assets/images/icon_tele.png" alt="" />
				<div class="phone-dtl-box">
					<div class="label">咨询热线</div>
					<div class="num">0591-87865886</div>
				</div>
			</div>
			<div class="ewm-box">
				<img src="@/assets/images/icon_ewm.png" alt="" />
				<div class="ewm-dtl-box">
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_ewm1.png" alt="" />
						</div>
						<p>企业微信客服</p>
					</div>
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_gzhewm.png" alt="" />
						</div>
						<p>关注企业公众号</p>
					</div>
				</div>
			</div>
			<div @click="upTop" style="background: #487cff" class="phone-box">
				<i class="iconfont" style="color: #fff">&#xe6ad;</i>
				<div class="phone-dtl-box">
					<div
						style="
							color: #333;
							line-height: 50px;
							text-align: center;
						"
					>
						返回顶部
					</div>
				</div>
			</div>
		</div>
		<van-popup
			v-model="menuType"
			position="right"
			:style="{ height: '100%' }"
		>
			<div class="h5-menu-box">
				<div class="logo-box">
					<img src="@/assets/h5-images/logo_blue@2x.png" />
				</div>
				<ul>
					<li @click="upTop2();menuType = false;$router.push('/')">
						首页
					</li>
					<li @click="upTop2();menuType = false;$router.push('/erp')">
						外贸ERP系统
					</li>
					<li  @click="upTop2();menuType = false;$router.push('/product')">
						云仓供应链
					</li>
					<li @click="upTop2();menuType = false;$router.push('/production')">
						数字生产系统
					</li>
					<!-- <li>
						客户案例
					</li> -->
				</ul>
			</div>
		</van-popup>
		<router-view />
		<footer style="margin-bottom:50px">
			<div class="phone-db" style="width:100%;padding:20px 20px">
				<img style="width:100%" src="@/assets/h5-images/h5_04.png" alt="">
			</div>
			<div class="footer-content phone-dn">
				<div class="contact">
					<div class="logo">
						<img src="@/assets/images/logo19060.png" alt="" />
					</div>
					<div class="phone">
						<div class="label">服务热线</div>
						<div class="num">0591-87865886</div>
					</div>
					<div class="address">
						联系地址：福建省福州市鼓楼区软件大道89号<br />软件园A区28栋5层
					</div>
				</div>
				<div class="move-box">
					<ul>
						<div class="label">更多产品</div>
						<li><a href="">云仓供应链系统</a></li>
						<li><a href="">外贸ERP管理系统</a></li>
						<li><a href="">生产管理系统</a></li>
					</ul>
					<ul>
						<div class="label">解决方案</div>
						<li><a href="">仓储管理</a></li>
						<li><a href="">客户管理</a></li>
						<li><a href="">订单跟进</a></li>
					</ul>
					<ul>
						<div class="label">相关信息</div>
						<li><a href="">关于我们</a></li>
						<li><a href="">客户案例</a></li>
						<li><a href="">最新动态</a></li>
					</ul>
				</div>
				<div class="ewm">
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_ewm1.png" alt="" />
						</div>
						<p>企业微信客服</p>
					</div>
					<div class="ewm-box">
						<div class="ewm-warp">
							<img src="@/assets/images/img_gzhewm.png" alt="" />
						</div>
						<p>关注企业公众号</p>
					</div>
				</div>
			</div>
			<div class="copyright phone-dn">
				<a target="_blank" href="https://beian.miit.gov.cn">
					Copyright© 福建数字云帆科技有限公司 版权所有
					闽ICP备2022002055号-1
				</a>
			</div>
			<van-overlay :show="show">
				<div class="commons-overlay-warp" @click.stop>
					<div class="title">
						申请试用系统
						<i class="iconfont" @click="show = false">&#xe6b3;</i>
					</div>
					<div class="label">具体申请方法</div>
					<div>
						1.咨询热线：<span style="color: #487cff"
							>0591-87865886</span
						>
					</div>
					<div>2.扫描咨询：</div>
					<div class="ewm">
						<div class="ewm-box">
							<div class="ewm-warp">
								<img
									src="@/assets/images/img_ewm1.png"
									alt=""
								/>
							</div>
							<p>企业微信客服</p>
						</div>
						<div class="ewm-box">
							<div class="ewm-warp">
								<img
									src="@/assets/images/img_gzhewm.png"
									alt=""
								/>
							</div>
							<p>关注企业公众号</p>
						</div>
					</div>
				</div>
			</van-overlay>
		</footer>
		<div class="footer-banner phone-db"  @click="show = true">
			<div class="left-box">
				<div>
					<img src="@/assets/h5-images/btn_phone@2x.png" alt="" />
					咨询热线
				</div>
				<div>
					<img src="@/assets/h5-images/btn_ewm@2x.png" alt="" />
					扫码关注
				</div>
			</div>
			<div class="right-box">
				申请试用
			</div>
		</div>
	</div>
</template>
<script>
import Vue from 'vue'
import { Sticky } from 'vant'
Vue.use(Sticky)
import { Overlay } from 'vant'
import 'animate.css'
Vue.use(Overlay)
import { Popup } from 'vant'
Vue.use(Popup)
export default {
	data() {
		return {
			headerClassName: '',
			show: false,
			type: 1,
			menuType: false,
		}
	},
	methods: {
		toLogin(){
			window.location.href = 'https://winfaster.cn/'
		},
		toAnli() {
			const currentY =
				document.documentElement.scrollTop || document.body.scrollTop
			this.scrollAnimation(currentY, 3800)
		},
		upTop() {
			console.log(this.$refs.app)
			const currentY =
				document.documentElement.scrollTop || document.body.scrollTop
			this.scrollAnimation(currentY, 0)
		},
		upTop2() {
			window.scrollTo(0, 0)
		},
		handleScroll(e) {
			if (document.documentElement.scrollTop > 760) {
				this.headerClassName = 'header-active'
			} else {
				this.headerClassName = ''
			}
		},
		scrollAnimation(currentY, targetY) {
			// 获取当前位置方法
			// const currentY = document.documentElement.scrollTop || document.body.scrollTop

			// 计算需要移动的距离
			let needScrollTop = targetY - currentY
			let _currentY = currentY
			setTimeout(() => {
				// 一次调用滑动帧数，每次调用会不一样
				const dist = Math.ceil(needScrollTop / 10)
				_currentY += dist
				window.scrollTo(_currentY, currentY)
				// 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
				if (needScrollTop > 10 || needScrollTop < -10) {
					this.scrollAnimation(_currentY, targetY)
				} else {
					window.scrollTo(_currentY, targetY)
				}
			}, 1)
		},
	},
	mounted() {
		window.addEventListener('scroll', this.handleScroll)
	},
}
</script>
<style lang="less">
#app {
	.footer-banner{
		position: fixed;
		left: 0;
		right: 0;
		bottom: 0;
		
		div{
			width: 50%;
			float: left;
			text-align: center;
			height: 50px;
		}
		.left-box{
			background: #fff;
			color: #333;
			font-size: 12px;
			div{
				padding-top:5px;
				img{
					display: block;
					width: 24px;
					height: 24px;
					margin: 0 auto;
				}
			}
		}
		.right-box{
			background: #487CFF;
			color: #fff;
			font-size: 16px;
			line-height: 50px;
		}
	}
	footer {
		background: #222222;
		height: 320px;
		padding: 50px 0 0 0;
		position: relative;
		.copyright {
			text-align: center;
			font-size: 12px;
			height: 40px;
			line-height: 40px;
			background: #333;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			a {
				color: #666;
			}
		}
		.footer-content {
			width: 1200px;
			margin: 0 auto;
			color: #fff;
			overflow: hidden;
			.ewm {
				float: right;
				padding-top: 20px;
				.ewm-box {
					width: 120px;
					margin-right: 20px;
					float: left;
					.ewm-warp {
						width: 120px;
						height: 120px;
						background: #fff;
						img {
							width: 100px;
							height: 100px;
							margin: 10px;
						}
					}
					p {
						text-align: center;
						font-size: 14px;
						line-height: 50px;
					}
				}
			}
			.move-box {
				width: 600px;
				overflow: hidden;
				float: left;
				padding-top: 20px;
				ul {
					width: 200px;
					float: left;
					padding-left: 80px;
					.label {
						font-size: 16px;
						color: #fff;
						font-weight: 700;
						margin-bottom: 30px;
					}
					li {
						font-size: 12px;

						a {
							color: #999999;
						}
						margin-bottom: 30px;
					}
				}
			}
			.contact {
				width: 300px;
				border-right: 1px solid #333333;
				height: 180px;
				float: left;
			}
			.phone {
				margin-top: 14px;
				margin-bottom: 10px;
				.label {
					margin-bottom: 10px;
					font-size: 16px;
				}
				.num {
					font-size: 26px;
				}
			}
			.address {
				font-size: 12px;
			}
		}
	}
	font-family: Microsoft YaHei, Microsoft YaHei-Bold;
	.tool-warp {
		position: fixed;
		right: 20px;
		top: 200px;
		z-index: 10;
	}
	.tool-warp > div {
		width: 50px;
		height: 50px;
		background: #fff;
		border-radius: 50%;
		box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
		text-align: center;
		margin-top: 30px;
		position: relative;
		cursor: pointer;
		.ewm-dtl-box {
			background: #ffffff;
			border: 1px solid #f1f1f1;
			border-radius: 6px;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
			position: absolute;
			width: 282px;
			padding: 20px;
			box-sizing: border-box;
			left: -298px;
			top: -60px;
			text-align: left;
			display: none;
			.ewm-box {
				width: 120px;
				float: left;
				.ewm-warp {
					width: 120px;
					height: 120px;
					background: #fff;
					img {
						width: 100px;
						height: 100px;
						margin: 10px;
					}
				}
				p {
					text-align: center;
					font-size: 14px;
					line-height: 30px;
				}
			}
		}

		.phone-dtl-box {
			height: 90px;
			background: #ffffff;
			border: 1px solid #f1f1f1;
			border-radius: 6px;
			box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
			position: absolute;
			width: 170px;
			padding: 20px;
			box-sizing: border-box;
			left: -185px;
			top: -20px;
			text-align: left;
			display: none;
			.label {
				font-size: 12px;
				color: #333;
			}
			.num {
				color: #487cff;
				font-size: 16px;
				font-weight: 700;
				margin-top: 8px;
			}
		}

		img {
			margin-top: 12px;
		}
		i {
			color: #487cff;
			font-size: 28px;
			line-height: 50px;
		}
	}
	.ewm-box:hover .ewm-dtl-box {
		display: block;
	}
	.phone-box:hover .phone-dtl-box {
		display: block;
	}
	.tool-warp > div:hover {
		background: rgb(212, 212, 212);
	}
	header {
		border-bottom: 1px solid;
		border-color: rgba(255, 255, 255, 0.2);
		font-size: 16px;
		color: #fff;
		transition: all 0.5s ease;
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0.2),
			rgba(36, 62, 128, 0)
		);
		position: fixed;
		z-index: 1000;
		top: 0;
		left: 0;
		right: 0;
		.header-warp {
			width: 1200px;
			margin: 0 auto;
			height: 80px;
			display: flex;
			justify-content: space-between;
			h1 {
				margin: 0;
				margin-top: 10px;
			}
			.user-btn {
				button {
					width: 80px;
					height: 36px;
					border-radius: 6px;
					box-sizing: border-box;
					font-size: 12px;
					border: none;
					margin-left: 20px;
					margin-top: 25px;
				}
				.btn-1 {
					background: #487cff;
					color: #fff;
				}
				.btn-2 {
					background: none;
					border: 1px solid #fff;
					color: #fff;
				}
				.btn-3 {
					background: #fff;
					color: #487cff;
				}
			}
			h1 {
				width: 190px;
			}
			.menu {
				display: flex;
				justify-content: space-between;
				li {
					padding: 0 20px;
					height: 80px;
					line-height: 80px;
					position: relative;
					cursor: pointer;
				}
				li.active::before {
					content: ' ';
					width: 30px;
					height: 4px;
					background: #fff;
					position: absolute;
					bottom: 0;
					left: 50%;
					margin-left: -15px;
				}
			}
		}
	}
	header.header-active {
		background: #487cff;
		.btn-1 {
			border: 1px solid #fff !important;
		}
	}
}
@media only screen and (max-width: 800px) {
	#app {
		width: 100%;
		overflow: hidden;
		footer{
			height: auto;
		}
		.tool-warp{
			display: none;
		}
		header {
			.header-warp {
				width: 100%;
				h1 {
					padding-left: 24px;
				}
				.menu {
					display: none;
				}
				.user-btn {
					display: none;
				}
				.open-menu-btn {
					display: block;
					line-height: 80px;
					width: 80px;
					text-align: center;
				}
			}
		}
	}
}
.h5-menu-box {
	width: 70vw;
	padding-top: 20px;
	.logo-box{
		border-bottom: 1px solid #DDDDDD;
		margin: 0 20px;
		padding-bottom:20px;
		
	}
	ul{
		padding: 0 20px;
		li{
			height: 40px;
			line-height:40px;
			font-size: 16px;
			color: #333;
		}
	}
	img {
		width: 130px;
		display: block;
		margin: 0 auto;
		
	}
}
</style>
