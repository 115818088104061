import Vue from 'vue';
import axios from 'axios';
import {getToken} from './util'
import router from '@/router'
import { Toast } from 'vant';
import { Dialog } from 'vant';
Vue.use(Toast);
axios.defaults.timeout = 65000;
axios.defaults.baseURL = '';
//http request 拦截器
axios.interceptors.request.use(
    config => {
        // const token = getCookie('名称');注意使用的时候需要引入cookie方法，推荐js-cookie
        var userJson = window.localStorage.getItem("token")
        if(!userJson){
            var userData = false
            var token;
        }else{
            var userData = JSON.parse(userJson)
            var token;
        }  
        if(userData && userData.token){
            token = 'Customer ' + userData.token
        }else{
            //教师
            token = "Customer eyJhbGciOiJIUzUxMiJ9.eyJ1c2VySWQiOjE0Njk1NjIxNTgwMjM4MDI4ODEsImFjY291bnQiOiIxNDcwMDAwMDQ4OCIsInV1aWQiOiIyYWIxMTcwOC0yYTlhLTQ2YzgtYTYyMy1jNWYwODY0ZGM1NzciLCJzdWIiOiIxNDY5NTYyMTU4MDIzODAyODgxIiwiaWF0IjoxNjQxNTQ1ODEwLCJleHAiOjE2NDQxMzc4MTB9.l0JT0OT2h96Di_WljOOm7XlPzquX0LzRtoYdo-kFl6WO_tN-VECfIoycBegrM1hVDBSDUz_uCOywy6PbhbyiEg"
            //学生
            //token = 'Customer eyJhbGciOiJIUzUxMiJ9.eyJ1c2VySWQiOjE0NzE3NjU4MTYyNDQ3NzI4NjUsImFjY291bnQiOiIxNTgwNjA2OTE5NSIsInV1aWQiOiI1OWUyNzBiMC0zZmRiLTRiZDItOWI3YS00MWRkMDc3MWUzMmQiLCJzdWIiOiIxNDcxNzY1ODE2MjQ0NzcyODY1IiwiaWF0IjoxNjQwNjA2Mjk0LCJleHAiOjE2NDMxOTgyOTR9.-StcC8aw03j-MkJj7kakJJHDNx1RwN13cz4nBFibE64vwGgulj-p5VF1xq4wfnNskbkHrlJdjl-83Xjol4_ILw'
            // Dialog.confirm({
            //     title: '当前token是：web写死',
            //     message: token,
            // })
        }
        
        config.data = JSON.stringify(config.data)
        config.headers = {
            'DeviceNo':"DeviceNo",
            'Content-Type': 'application/json;charset=UTF-8', 
            'Authorization': token,
            'source':1,
        }
        return config;
    },
    error => {
        return Promise.reject(err);
    }
);


//http response 拦截器
axios.interceptors.response.use(
    response => {
        console.log(response)
        if (response.data.code == 1011008 || response.data.code == 1011006) {
            // Toast.fail('登录过期，跳原生')
            let userData
            var u = navigator.userAgent;
            var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
            var isiOS = u.indexOf('Mac OS X') > -1
            try{
                if(isAndroid){
                    window.jsi.logout()
                }
                if(isiOS){
                    
                }
            }catch(err){
            }
        }
        if (response.data.code != 200) {
            Toast.fail(response.data.message)
        }
        
        switch (response.data.code) {
            case 32:
                response.msg = '请求错误(400)'
                break;
        }
        return response;
    },
    err => {
        console.log(err)
        if (err && err.response) {
            switch (err.response.status) {
                case 400:
                    err.message = '请求错误(400)';
                    break;
                case 401:
                    err.message = '未授权，请重新登录(401)';
                    break;
                case 403:
                    err.message = '拒绝访问(403)';
                    break;
                case 404:
                    err.message = '请求出错(404)';
                    break;
                case 408:
                    err.message = '请求超时(408)';
                    break;
                case 500:
                    err.message = '服务器错误(500)';
                    break;
                case 501:
                    err.message = '服务未实现(501)';
                    break;
                case 502:
                    err.message = '网络错误(502)';
                    break;
                case 503:
                    err.message = '服务不可用(503)';
                    break;
                case 504:
                    err.message = '网络超时(504)';
                    break;
                case 505:
                    err.message = 'HTTP版本不受支持(505)';
                    break;
                default:
                    err.message = `连接出错(${err.response.status})!`;
            } 
            // if(err.response.status == 404){
            //     router.push("/*")
            // }
            // if(err.response.status == 500 || err.response.status == 502){
            //     router.push("/500")
            // }
            // if(err.response.status == 401){
            //     router.push("/401")
            // }
        } else {
            err.message = '连接服务器失败!'
        }

        return Promise.reject(err);
    }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        console.log(process.env.NODE_ENV == "development")
        if(process.env.NODE_ENV == "development"){
            var postUrl = "http://" + window.location.host + "/api" + url
        }else{
            var postUrl = "https://" + window.location.host + "/api" + url
        }
        axios.get(postUrl, {params:params})
            .then(response => {
                setTimeout(() => {
                }, 500)
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        console.log(process.env.NODE_ENV == "development")
        if(process.env.NODE_ENV == "development"){
            var postUrl = "http://" + window.location.host + "/api" + url
        }else{
            var postUrl = "https://" + window.location.host + "/api" + url
        }
        axios.post(postUrl, data)
            .then(response => {
                setTimeout(() => {
                }, 500)
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

export default {
    post,
    get
}